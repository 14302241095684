import React, { useContext } from 'react';

import { AuthContext } from '../../../../app/context/AuthContext';
import { EditorContext } from '../../../../components/contexts';
import { useSaveableInput } from '../../../../components/hooks/useSaveableInput';
import useUnsavedChangesWarning from '../../../../components/hooks/useSavedForm';
import { Input } from '../../../../components/inputs/Input';
import { GridRow } from '../../../../components/layouts/GridRow';
import status from '../../../../translations/status';
import { ImageUpload } from '../../CreateArticle/ImageUpload';
import { H1 } from '../../CreateArticle/Style';
import { Container } from './Style';

export const EditArticleForm = ({
  handleImageUpload,
  imageUrl,
  languageArticle,
  setTitleTranslations,
  titleTranslations,
}) => {
  const { user } = useContext(AuthContext);
  const { editorRef } = useContext(EditorContext);
  const appLocale = user ? user.appLocale : 'fr';

  const { setSavedForm } = useUnsavedChangesWarning(appLocale, status);

  const handleSaveTitle = (newValue) => {
    setTitleTranslations((prevTranslations) => ({
      ...prevTranslations,
      [languageArticle]: newValue,
    }));
  };

  const { inputValue, handleInputChange, handleInputFocus, handleKeyDown, isEditing, setIsEditing, inputRef } =
    useSaveableInput(titleTranslations[languageArticle], handleSaveTitle);

  const handleTitleClick = () => {
    setIsEditing(true);
  };

  return (
    <>
      <GridRow col="2" width="100%">
        {isEditing ? (
          <Input
            name="title"
            type="text"
            value={inputValue}
            onChange={(e) => {
              handleInputChange(e);
              setSavedForm(false);
            }}
            onFocus={handleInputFocus}
            onKeyDown={handleKeyDown}
            ref={inputRef}
            onBlur={() => setIsEditing(false)}
          />
        ) : (
          <H1 onClick={handleTitleClick}>{inputValue}</H1>
        )}
        <ImageUpload imageUrl={imageUrl} handleImageUpload={handleImageUpload} />
      </GridRow>
      <Container>
        <div
          ref={editorRef}
          onClick={() => {
            setSavedForm(false);
          }}
          role="textbox"
          onKeyDown={() => {
            setSavedForm(false);
          }}
        ></div>
      </Container>
    </>
  );
};
