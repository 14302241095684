import styled from 'styled-components';
import { Card as CardRef } from '../../../../components/layouts/Card';

export const Layout = styled.div`
  overflow: hidden;
  padding-top: 4rem;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Card = styled(CardRef)`
  margin: 0;
  margin-bottom: 3rem;
  align-items: flex-start;
  justify-content: flex-start;
  gap: ${({ gap }) => gap};
`;

export const PanelContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${({ panelView }) => (panelView === 0 ? '50%' : '90%')};
  height: 100%;
  /* padding: 0 2rem; */
  transition: transform 0.35s ease-in-out;
  transform: translateX(${({ panelView }) => (panelView === 0 ? '0' : '100%')});
`;

export const SlideOutPanel = styled.div`
  width: 100%;
  height: 100%;
  transition: transform 0.5s ease-in-out;
  transform: translateX(-100%);
`;
