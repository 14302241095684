import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useArticleContext } from '../../../app/context/ArticleContext/index.js';
import { AuthorsContext } from '../../../app/context/AuthorsContext/index.js';
import { useArticleData as useArticleData2 } from '../../../components/hooks/article/useArticleData.js';
import { useHighlight } from '../../../components/hooks/useHighlight';
import { useOpenAI } from '../../../components/hooks/useOpenAI';
import { PageContainer } from '../../../components/layouts/PageContainer';
import { RightPanelModal } from '../../../components/layouts/RightPanelModal/index.js';
import { useArticleData } from '../context/ArticleDataProvider/index.js';
import { EditArticleForm } from './EditArticleForm';
import { Container } from './EditArticleForm/Style';
import { MetaPanel } from './MetaPanel/index.js';
import { MetaTextSuggestion } from './MetaTextSuggestion';
import { MobilePreview } from './MobilePreview/index.js';

export const EditArticle = ({
  handleImageUpload,
  imageUrl,
  languageArticle,
  previewModal,
  setContentTranslations,
  setEnglishStatus,
  setFrenchStatus,
  setTitleTranslations,
  setViewModal,
  titleTranslations,
  viewModal,
}) => {
  const { id } = useParams();

  const {
    sources,
    addSourceToArticle,
    removeSourceToArticle,
    editSourceToArticle,
    seo,
    addSeoToArticle,
    keywords,
    editKeywords,
    updateArticleStatus,
    frenchStatus,
    englishStatus,
  } = useArticleData2(id);

  const { article } = useArticleData(id).articleStore;

  const { getArticle } = useArticleContext();

  useEffect(() => {
    getArticle(id);
  }, []);

  useEffect(() => {
    setFrenchStatus(frenchStatus);
  }, [frenchStatus]);

  useEffect(() => {
    setEnglishStatus(englishStatus);
  }, [englishStatus]);

  const { authorInfo, allAuthorsOptions, assignAuthorToArticle, removeAuthorFromArticle } = useContext(AuthorsContext);

  const [highlightCardOption, setHighlightCardOption] = useState(0);

  const [text, setText] = useState('');
  const [metaTextSuggestionActive, setMetaTextSuggestionActive] = useState(false);

  const { response, handleClick } = useOpenAI(text, metaTextSuggestionActive);
  const { highlightedText, isLoading } = useHighlight(handleClick);

  useEffect(() => {
    if (highlightedText) {
      setText(highlightedText);
    }
  }, [highlightedText]);

  return (
    <PageContainer>
      <Container>
        {previewModal ? (
          <MobilePreview languageArticle={languageArticle} initialValues={article.translations} />
        ) : (
          <>
            {article.translations && (
              <EditArticleForm
                formType="edit"
                handleImageUpload={handleImageUpload}
                id={id}
                imageUrl={imageUrl}
                initialValues={article.translations}
                languageArticle={languageArticle}
                setContentTranslations={setContentTranslations}
                setTitleTranslations={setTitleTranslations}
                titleTranslations={titleTranslations}
              />
            )}

            {metaTextSuggestionActive && (
              <MetaTextSuggestion
                highlightedText={highlightedText}
                response={response}
                isLoading={isLoading}
                highlightCardOption={highlightCardOption}
                setHighlightCardOption={setHighlightCardOption}
              />
            )}
          </>
        )}
      </Container>
      <RightPanelModal size padding="0" shouldShow={viewModal} onRequestClose={() => setViewModal(false)}>
        <MetaPanel
          metaTextSuggestionActive={metaTextSuggestionActive}
          setMetaTextSuggestionActive={setMetaTextSuggestionActive}
          author={authorInfo}
          allAuthorsOptions={allAuthorsOptions}
          sources={sources}
          assignAuthorToArticle={assignAuthorToArticle}
          removeAuthorFromArticle={removeAuthorFromArticle}
          addSourceToArticle={addSourceToArticle}
          removeSourceToArticle={removeSourceToArticle}
          editSourceToArticle={editSourceToArticle}
          keywords={keywords}
          editKeywords={editKeywords}
          seoData={seo}
          addSeoToArticle={addSeoToArticle}
          article={article}
          updateArticleStatus={updateArticleStatus}
          frenchStatus={frenchStatus}
          englishStatus={englishStatus}
        />
      </RightPanelModal>
    </PageContainer>
  );
};
