import React, { useRef, useState, useEffect } from 'react';
import { MainImageArticle, MainImageWrapper } from '../../EditArticle/EditArticleForm/Style';
import { UploadButton } from '../../../../components/inputs/UploadButton';
import useUnsavedChangesWarning from '../../../../components/hooks/useSavedForm';

export const ImageUpload = ({ imageUrl, handleImageUpload, isUploading }) => {
  const fileInputRef = useRef(null);
  const { setSavedForm } = useUnsavedChangesWarning();
  // const [image, setImage] = useState(imageUrl)

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        await handleImageUpload(file);
        setSavedForm(false);
      } catch (error) {
        console.error('Error uploading image:', error);
      }
    }
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  return (
    <MainImageWrapper onClick={handleButtonClick}>
      {imageUrl ? (
        <MainImageArticle src={`${imageUrl}`}></MainImageArticle>
      ) : (
        <div>
          <UploadButton isUploading={isUploading}></UploadButton>
        </div>
      )}
      <input
        ref={fileInputRef}
        id="imageUploadInput"
        type="file"
        accept="image/*"
        onChange={handleFileChange}
        style={{ display: 'none' }}
      />
    </MainImageWrapper>
  );
};
