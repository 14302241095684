import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './app/index';
import { BrowserRouter as Router } from 'react-router-dom';

import { ThemeProvider } from 'styled-components';
import { themeSettings } from './app/theme/theme-settings';
// import { AuthorsProvider } from './features/settings/AuthorTable/useAuthors'

import { ArticleDataContext, ArticleProvider } from './features/articleManagement/context/ArticleDataProvider';
// import { CategoriesProvider } from './features/articleManagement/context/CategoriesProvider'
import { CategoriesProvider } from './features/articleManagement/context/CategoriesProvider';
import { AuthProvider } from './app/context/AuthContext';
import { CategoriesProviderSettings } from './features/settings/CategoryTable/useCategories';
import { ProviderTest } from './features/articleManagement/context/CategoriesProvider/TextInputTest';

import { AuthorsProvider } from './app/context/AuthorsContext';
import { ArticleProvider2 } from './app/context/ArticleContext';
import { ModalProvider } from './app/context/PopUpModalContext';
import { SavedFormProvider } from './app/context/SavedFormContext';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <ProviderTest>
    {/*<AuthProvider>*/}
    <ModalProvider>
      <ArticleProvider>
        <ArticleProvider2>
          <AuthorsProvider>
            <CategoriesProviderSettings>
              {/* <CategoriesProvider> */}
              <CategoriesProvider>
                <SavedFormProvider>
                  <ThemeProvider theme={themeSettings}>
                    <Router>
                      <App />
                    </Router>
                  </ThemeProvider>
                </SavedFormProvider>
              </CategoriesProvider>
              {/* </CategoriesProvider> */}
            </CategoriesProviderSettings>
          </AuthorsProvider>
        </ArticleProvider2>
      </ArticleProvider>
    </ModalProvider>
    {/*</AuthProvider>*/}
  </ProviderTest>,
);
