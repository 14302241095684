import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { useArticleContext } from '../../app/context/ArticleContext';
import { AuthContext } from '../../app/context/AuthContext';
import { useModal } from '../../app/context/PopUpModalContext';
import { ErrorLottie } from '../../components/animations/ErrorLottie';
import { ArticleLink } from '../../components/articleLink';
import { EditorContext, EditorContextProvider } from '../../components/contexts';
import Button from '../../components/elements/Button';
import ButtonSpinner from '../../components/elements/Button/ButtonSpinner';
import { LanguageToggle } from '../../components/elements/LanguageToggle';
import { useImageUpload } from '../../components/hooks/article/useImageUpload';
import { useEditor } from '../../components/hooks/useEditorJS1';
import { ContainerHeader } from '../../components/layouts/ContainerHeader';
import { ExpandableTriangle } from '../../components/layouts/ExpandableTriangle';
import { Header } from '../../components/layouts/Header';
import { Modal } from '../../components/layouts/PopUpModal';
import { Button as PopupButton } from '../../components/layouts/PopUpModal/Style';
import { EditArticle } from '../../features/articleManagement/EditArticle';
import { Select } from '../../features/articleManagement/EditArticle/MetaPanel/Categories/CategoryManager/Style';
import { SaveArticleButton } from '../../features/articleManagement/EditArticle/SaveArticleButton';
import translationsPublish from '../../translations/publish';
import translationsStatus from '../../translations/status';
import ApiHttpClient from '../../utils/api/api-http-client';

export const EditArticlePage = (props) => {
  return (
    <EditorContextProvider>
      <EditArticlePageContainer {...props} />
    </EditorContextProvider>
  );
};

export const EditArticlePageContainer = () => {
  const apiClient = new ApiHttpClient();

  const theme = useTheme();
  const { id } = useParams();
  const { appLocale } = useContext(AuthContext)?.user || {};
  const { editorRef, isEditorReady, setIsEditorReady } = useContext(EditorContext);

  const [languageArticle, setLanguageArticle] = useState('fr');
  const [viewModal, setViewModal] = useState(false);
  const [previewModal, setPreviewModal] = useState(false);
  const [englishStatus, setEnglishStatus] = useState();
  const [frenchStatus, setFrenchStatus] = useState();
  const [hideButton, setHideButton] = useState(true);
  const [onClose, setOnClose] = useState();
  const { currentArticle } = useArticleContext();
  const [publishedStatus, setPublishedStatus] = useState('');
  const [contentOrTitleUpdated, setContentOrTitleUpdated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState(currentArticle?.translations || []);
  const [translation, setTranslation] = useState({});

  const getTranslation = (locale, field, defaultValue) =>
    initialValues.find((t) => t.locale === locale)?.[field] ?? defaultValue;

  const getTranslations = (field, defaultValue) => {
    return ['fr', 'en'].reduce((acc, cv) => {
      return {
        ...acc,
        [cv]: getTranslation(cv, field, defaultValue),
      };
    }, {});
  };

  const [titleTranslations, setTitleTranslations] = useState(getTranslations('title', ''));
  const [contentTranslations, setContentTranslations] = useState(getTranslations('content', []));

  const { imageUrl, handleImageUpload } = useImageUpload(initialValues[0].image);

  const { editor, refreshEditorContent } = useEditor({
    contentTranslations,
    editorRef,
    isEditorReady,
    languageArticle,
    setContentTranslations,
    setIsEditorReady,
  });

  const { setModalContent } = useModal();

  useEffect(() => {
    if (appLocale) {
      getPublishedStatus();
    }

    if (Array.isArray(currentArticle?.translations)) {
      setInitialValues(currentArticle.translations);
      setTranslation(currentArticle.translations.find((t) => t.locale === languageArticle) || {});
    }
  }, [languageArticle, currentArticle, appLocale]);

  useEffect(() => {
    setContentTranslations(getTranslations('content', []));
    setTitleTranslations(getTranslations('title', ''));
    setContentOrTitleUpdated(true);
  }, [initialValues]);

  useEffect(() => {
    if (contentOrTitleUpdated) {
      refreshEditorContent();
      setContentOrTitleUpdated(false);
    }
  }, [contentOrTitleUpdated]);

  const getOptions = () => {
    const options = [];
    for (const [key, value] of Object.entries(translationsPublish[appLocale])) {
      options.push({ value: key, label: value });
    }
    return options;
  };

  const publish = async (id, lg) => {
    try {
      setLoading(true);
      setModalContent(
        <>
          <h2>{appLocale === 'en' ? 'Publish Article' : "Publier l'article"}</h2>
          <Select
            defaultValue={getOptions()[0]}
            options={getOptions()}
            onChange={(e) => {
              lg = e.value;
            }}
            menuPlacement="auto"
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              marginTop: theme.spacing.md,
            }}
          >
            <PopupButton
              style={{
                margin: 0,
                width: '45%',
                backgroundColor: theme.colors.warning,
              }}
              disabled
            >
              {appLocale === 'en' ? 'Cancel' : 'Annuler'}
            </PopupButton>
            <PopupButton style={{ margin: 0, width: '45%', marginLeft: 'auto' }} disabled={loading}>
              <ButtonSpinner />
            </PopupButton>
          </div>
        </>,
      );
      const languages = [];
      if (lg === 'FR' || lg === 'BOTH') {
        languages.push('fr');
      }
      if (lg === 'EN' || lg === 'BOTH') {
        languages.push('en');
      }
      const res = await apiClient.put(`/articles/${id}/publish`, { languages });

      if (res.data.success) {
        setOnClose(() => window.location.reload());
      } else {
        throw new Error('Publishing failed');
      }
    } catch (error) {
      setHideButton(false);
      setModalContent(
        <>
          <ErrorLottie /> {appLocale === 'en' ? 'Failed to publish the article' : 'Échec de publication'}
        </>,
      );
    } finally {
      setLoading(false);
    }
  };

  const publishArticle = (id) => {
    let lg = 'FR';
    setHideButton(true);
    setOnClose(() => {
      setHideButton(true);
    });
    setModalContent(
      <>
        <h2>{appLocale === 'en' ? 'Publish Article' : "Publier l'article"}</h2>
        <Select
          defaultValue={getOptions()[0]}
          options={getOptions()}
          onChange={(e) => {
            lg = e.value;
          }}
          menuPlacement="auto"
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            marginTop: theme.spacing.md,
          }}
        >
          <PopupButton
            style={{
              margin: 0,
              width: '45%',
              backgroundColor: theme.colors.warning,
            }}
            onClick={() => setModalContent(null)}
          >
            {appLocale === 'en' ? 'Cancel' : 'Annuler'}
          </PopupButton>
          <PopupButton style={{ margin: 0, width: '45%', marginLeft: 'auto' }} onClick={() => publish(id, lg)}>
            {appLocale === 'en' ? 'Publish' : 'Publier'}
          </PopupButton>
        </div>
      </>,
    );
  };

  const getStatus = () => {
    if (!appLocale) return;
    return translationsStatus[appLocale][languageArticle === 'en' ? englishStatus : frenchStatus];
  };

  const getPublishedStatus = () => {
    const translation = currentArticle?.translations.find((t) => t.locale === languageArticle);
    if (translation?.publishDate) {
      let publishedOn = appLocale === 'en' ? 'Last version published: ' : 'Dernière version publiée: ';
      publishedOn += new Date(translation.publishDate).toISOString().split('T')[0];
      setPublishedStatus(publishedOn);
    } else {
      setPublishedStatus(appLocale === 'en' ? 'Not published' : 'Non publié');
    }
  };

  return (
    <>
      <ContainerHeader>
        <Header>
          {isEditorReady && (
            <>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <LanguageToggle languageArticle={languageArticle} setLanguageArticle={setLanguageArticle} />
                <div
                  style={{
                    marginLeft: '2rem',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <span
                    style={{
                      fontSize: '12px',
                      fontWeight: theme.typography.fontWeight.bold,
                      color: theme.colors.primary,
                    }}
                  >
                    {getStatus()}
                  </span>
                </div>
              </div>
              <div style={{ marginLeft: 'auto', marginRight: '2rem' }}>
                <ArticleLink translation={translation} locale={languageArticle} />
              </div>
              <div>
                <span
                  style={{
                    marginRight: '2rem',
                    fontSize: '12px',
                    fontWeight: theme.typography.fontWeight.bold,
                    color: theme.colors.primary,
                  }}
                >
                  {publishedStatus}
                </span>
              </div>

              <SaveArticleButton
                contentTranslations={contentTranslations}
                editor={editor}
                formType="edit"
                id={id}
                languageArticle={languageArticle}
                titleTranslations={titleTranslations}
                imageUrl={imageUrl}
              />

              <div>
                {appLocale && (
                  <Button onClick={() => publishArticle(id)}>
                    {appLocale === 'en' ? 'Publish' : 'Publier'} &nbsp;<i class="fa-solid fa-file-circle-check"></i>
                  </Button>
                )}
                <ExpandableTriangle onClick={() => setViewModal(true)} />
              </div>
            </>
          )}
        </Header>
      </ContainerHeader>

      <Modal hideButton={hideButton} onClose={onClose} />
      <EditArticle
        handleImageUpload={handleImageUpload}
        imageUrl={imageUrl}
        languageArticle={languageArticle}
        previewModal={previewModal}
        setContentTranslations={setContentTranslations}
        setEnglishStatus={setEnglishStatus}
        setFrenchStatus={setFrenchStatus}
        setPreviewModal={setPreviewModal}
        setTitleTranslations={setTitleTranslations}
        setViewModal={setViewModal}
        titleTranslations={titleTranslations}
        viewModal={viewModal}
      />
    </>
  );
};
