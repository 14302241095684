import { useCallback, useEffect } from 'react';
import { useSavedForm } from '../../app/context/SavedFormContext';

const useUnsavedChangesWarning = () => {
  const { savedForm, setSavedForm } = useSavedForm();

  const handleBeforeUnload = useCallback((e) => {
    if (!savedForm) {
        e.preventDefault();
        e.returnValue = '';
        return e.returnValue;       
    }
  }, [savedForm]);

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [handleBeforeUnload]);

  return { savedForm, setSavedForm };
};

export default useUnsavedChangesWarning;