import React, { useContext, useState } from 'react';
import { useTheme } from 'styled-components';

import { AuthContext } from '../../../../app/context/AuthContext';
import Button from '../../../../components/elements/Button';
import useUnsavedChangesWarning from '../../../../components/hooks/useSavedForm';
import { sendDataToServer } from '../../../../features/articleManagement/CreateArticle/requestBodyEditor';
import { useCategoriesStore } from '../../../../features/articleManagement/context/CategoriesProvider';
import status from '../../../../translations/status';

const interfaceTexts = {
  fr: {
    saveButton: 'Enregistrer',
    saving: 'Enregistrement...',
    savedSuccessfully: 'Enregistré avec succès',
    saveError: "Erreur lors de l'enregistrement",
    largeError: "L'article est trop grand pour être enregistré.",
  },
  en: {
    saveButton: 'Save',
    saving: 'Saving...',
    savedSuccessfully: 'Saved successfully',
    saveError: 'Error while saving',
    largeError: 'The article is too large to save.',
  },
};

export const SaveArticleButton = ({
  contentTranslations,
  editor,
  formType,
  id,
  imageUrl,
  languageArticle,
  titleTranslations,
}) => {
  const theme = useTheme();
  const { user } = useContext(AuthContext);
  const appLocale = user ? user.appLocale : 'fr';

  const [categoryStore] = useCategoriesStore((store) => store);
  const { setSavedForm } = useUnsavedChangesWarning(appLocale, status);

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [errorLarge, setErrorLarge] = useState(false);

  const handleSave = async (e) => {
    setLoading(true);
    setSuccess(false);
    setError(false);

    try {
      const savedData = await editor.save();

      const updatedContentTranslations = { ...contentTranslations };
      updatedContentTranslations[languageArticle] = savedData.blocks;
      let categories = [];
      categoryStore.categoryInfo.categories.forEach((c) => {
        categories.push(c);
      });

      const result = await sendDataToServer({
        locale: languageArticle,
        translations: updatedContentTranslations,
        titleTranslations,
        image: imageUrl,
        formType,
        id,
        categories,
      });

      if (result.success) {
        setLoading(false);
        setSuccess(true);
        setSavedForm(true);
        setTimeout(() => {
          setSuccess(false);
        }, 4000);
      } else if (result.errorMessage === 'Request failed with status code 413') {
        setLoading(false);
        setErrorLarge(true);
        setTimeout(() => {
          setErrorLarge(false);
        }, 4000);
      } else {
        setLoading(false);
        setError(true);
        setTimeout(() => {
          setError(false);
        }, 4000);
      }
    } catch (error) {
      setLoading(false);
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 4000);
    }
  };

  return (
    <Button
      onClick={handleSave}
      style={{
        marginRight: '1rem',
        backgroundColor: 'transparent',
        color: theme.colors.primary,
      }}
      borderColor={theme.colors.primary}
    >
      {loading && !success && !error && interfaceTexts[appLocale]?.saving}
      {!loading && success && !error && interfaceTexts[appLocale]?.savedSuccessfully}
      {!loading && !success && error && interfaceTexts[appLocale]?.saveError}
      {!loading && !success && errorLarge && interfaceTexts[appLocale]?.largeError}
      {!loading && !success && !error && !errorLarge && interfaceTexts[appLocale]?.saveButton}
    </Button>
  );
};
