import { createContext, useContext, useMemo, useState } from 'react';

const SavedFormContext = createContext();

export const SavedFormProvider = ({ children }) => {
  const [savedForm, setSavedForm] = useState(true);

  const value = useMemo(() => ({ savedForm, setSavedForm }), [savedForm]);

  return <SavedFormContext.Provider value={value}>{children}</SavedFormContext.Provider>;
};

export const useSavedForm = () => useContext(SavedFormContext);