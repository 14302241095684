import EditorJS from '@editorjs/editorjs';
import { useEffect, useState } from 'react';

import { EDITOR_JS_TOOLS } from '../../features/articleManagement/EditArticle/EditorJS_Tools/EditorJS_constants';

export const useEditor = ({
  editorRef,
  contentTranslations,
  languageArticle,
  setContentTranslations,
  isEditorReady,
  setIsEditorReady,
}) => {
  const [editor, setEditor] = useState(null);
  const [previousLanguage, setPreviousLanguage] = useState(languageArticle);

  useEffect(() => {
    if (!editorRef.current) {
      return;
    }

    const initializeEditor = () => {
      try {
        const newEditor = new EditorJS({
          holder: editorRef.current,
          tools: EDITOR_JS_TOOLS,
          data: {
            blocks: contentTranslations[languageArticle] || [],
          },
          onReady: () => {
            setEditor(newEditor);
            setIsEditorReady(true);
          },
        });
      } catch (error) {
        console.error('Error initializing editor:', error);
      }
    };

    initializeEditor();

    return () => {
      if (editor) {
        editor.destroy();
      }
    };
  }, [editorRef.current]);

  const updateContent = async () => {
    if (editor && isEditorReady && typeof editor.save === 'function') {
      const savedBlocks = await editor.save();

      setContentTranslations((prevState) => ({
        ...prevState,
        [previousLanguage]: savedBlocks.blocks || [],
      }));

      if (Array.isArray(contentTranslations[languageArticle]) && contentTranslations[languageArticle].length === 0) {
        editor.render({
          blocks: [
            {
              type: 'paragraph',
              data: {
                text: '',
              },
            },
          ],
        });
      } else if (contentTranslations[languageArticle].length > 0) {
        editor.render({ blocks: contentTranslations[languageArticle] || [] });
      }
    }
  };

  useEffect(() => {
    if (editor && isEditorReady && previousLanguage !== null) {
      updateContent();
    }
  }, [editor, isEditorReady, languageArticle, previousLanguage]);

  useEffect(() => {
    if (editor && isEditorReady && languageArticle !== previousLanguage) {
      // Update the previousLanguage when languageArticle changes
      setPreviousLanguage(languageArticle);
    }
  }, [editor, isEditorReady, languageArticle, previousLanguage]);

  return { editor, refreshEditorContent: updateContent };
};
