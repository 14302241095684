import styled from 'styled-components/macro';

import Button from '../../../../components/elements/Button';
import { H1 as H1r } from '../../../../components/elements/H1';
import { Image } from '../../../../components/elements/Image';

export const MainImageArticle = styled(Image)`
  width: 100%;
  aspect-ratio: 3/2;
  margin: 3rem auto;
  object-fit: cover;
  height: auto;

  .edit-icon {
    display: none;
  }

  &:hover {
    .edit-icon {
      display: flex;
    }
  }
`;

export const MainImageWrapper = styled.div`
  position: relative;

  .image-edit-icon {
    position: absolute !important;
    top: 2.7rem;
    right: -4rem;
    font-size: 3.25rem;

    --fa-primary-color: ${({ theme }) => theme.colors.primary2};
    --fa-secondary-color: ${({ theme }) => theme.colors.primary};
  }
`;

export const H1 = styled(H1r)`
  margin-left: 5rem;
  margin-bottom: 0.2rem;

  .image-edit-icon {
    position: absolute;
    right: -4rem;
    top: -0.5rem;
    font-size: 3.25rem;

    --fa-primary-color: ${({ theme }) => theme.colors.primary2};
    --fa-secondary-color: ${({ theme }) => theme.colors.primary};
  }
`;

export const Container = styled.div`
  position: relative;
  width: 85%;
  margin: auto;
  margin-top: 5rem;
  margin-bottom: 5rem;
  overflow: hidden;

  .ce-popover {
    z-index: 10 !important;
  }

  .codex-editor * {
    line-height: 4.5rem;

    .ce-toolbar__content {
      transform: translateY(0.7rem);
    }

    h2 {
      color: ${({ theme }) => theme.colors.primary};
    }

    h2 {
      font-size: 3rem;
    }
    h3 {
      font-size: 2.4rem;
    }
    h4 {
      font-size: 2rem;
    }
  }

  .codex-editor__redactor {
    padding-bottom: 20rem !important;
  }
`;

export const TextContainer = styled.div`
  font-size: 16px;
  line-height: 1.5;
`;

export const HighlightedText = styled.span`
  background-color: yellow;
`;

export const HighlightCard = styled.div`
  width: 80rem;
  position: fixed;
  top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1.8rem 2.4rem;
  /* border: 1px solid #ddd; */
  line-height: 2;
  background: #ffffff;
  box-shadow: 0px 4px 14px 1px rgba(160, 160, 160, 0.25);
  border-radius: 15px;
  z-index: 2;
  > div {
    /* margin-bottom: 1rem; */
    span {
      color: #d2d2d2;
      margin: 0 1.4rem;
      font-size: 1.2rem;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  > div > .selected {
    color: ${(props) => props.theme.colors.primary};
    font-weight: bold;
  }

  .options {
    cursor: pointer;

    &:hover {
      color: ${(props) => props.theme.colors.primary};
      font-weight: 700;
    }
  }

  .separator {
    color: #b2b2b2;
    &:last-of-type {
      display: none;
    }
  }
`;

export const TotalWords = styled.div`
  color: #b2b2b2;
  font-size: 1.3rem;
  margin: 0;
  margin-left: auto;
  margin-top: 0.7rem;
  width: fit-content;

  span {
    color: ${({ theme }) => theme.colors.primary} !important;
    font-weight: bold;
    margin: 0 0.22rem !important;
  }
`;

export const FloatingSaveButton = styled(Button)`
  position: fixed;
  top: 2rem;
  right: 2rem;
`;
