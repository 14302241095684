import { useRef } from 'react';
import styled from 'styled-components/macro';

const ModalBackground = styled.div`
  position: fixed;
  z-index: 2;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  /* background-color: #95909064;
  background-color: #fafafa40; */
  margin-top: 0rem !important;

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  button.close {
    position: absolute;
    display: flex;
    border: 0;
    top: 1.05rem;
    right: 1.05rem;
    background: transparent;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.primary}40;
    margin-left: auto;

    i.fa-solid.fa-circle-xmark {
    }
    /* background: #000; */
  }
`;

const ModalBody = styled.div`
  position: relative;
  position: absolute;
  right: 0;
  background-color: white;
  /* margin: 10% auto; */
  margin-left: auto;
  padding: ${({ padding }) => padding || '2rem'};
  height: ${(props) => props.height || '100%'};
  width: ${(props) => (props.shouldShow ? '80%' : '0%')};
  transition: ${({ theme }) => theme.transitionSpeed} all ease-in-out;
  box-shadow: 0px 1px 28px rgba(0, 0, 0, 0.12);
  transform: ${(props) => (props.shouldShow ? 'translateX(0)' : 'translateX(100%)')};

  button {
    margin-left: auto;
    > i.fa-solid {
      /* background: #000; */
      border: none;
    }
  }
`;

export const RightPanelModal = ({ shouldShow, size, onRequestClose, children, height, padding }) => {
  const modalBody = useRef();

  const onBackgroundClick = (e) => {
    let modal = modalBody.current.getBoundingClientRect();

    if (e.pageX < modal.x) {
      onRequestClose();
    }
  };

  // const closeModal = () => {
  //   // Hide the modal when the x button is clicked
  //   shouldShow = false
  // }

  return shouldShow ? (
    <ModalBackground onClick={onBackgroundClick} shouldShow={shouldShow}>
      <ModalBody ref={modalBody} height={height} shouldShow={shouldShow} padding={padding}>
        <button className="close" onClick={onRequestClose}>
          <i className="fa-solid fa-circle-xmark"></i>
        </button>
        {children}
      </ModalBody>
    </ModalBackground>
  ) : null;
};
